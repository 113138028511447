body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body {
  background-color: white;
}

button:focus, a:focus {
  outline: 0;
}

a {
  color: rgb(56, 56, 56);
}

a:hover {
  color: rgb(235, 133, 0);
}

div#drum-machine {
  margin: 5% auto;
  background-color: black;
  width: 750px;
  height: auto;
  padding: 4%;
  border-radius: 8px;
  box-shadow: 5px 5px 0px rgb(56, 56, 56),
              6px 6px 0px rgb(56, 56, 56),
              7px 7px 0px rgb(56, 56, 56),
              8px 8px 0px rgb(56, 56, 56),
              9px 9px 0px rgb(56, 56, 56),
              10px 10px 0px rgb(56, 56, 56);
}

.drum-pad {
  color: white;
  background-color: gray;
  font-size: 35px;
  width: 90%;
  height: 100px;
  border-radius: 4px;
  border:none;
  margin-bottom: 15px;
  box-shadow: 1px 1px 0px rgb(56,56,56),
              2px 2px 0px rgb(56,56,56),
              3px 3px 0px rgb(56,56,56),
              4px 4px 0px rgb(56,56,56);
}

.drum-pad:active {
  position: relative;
  color: white;
  background-color: gray;
  font-size: 35px;
  width: 90%;
  height: 100px;
  margin-bottom: 15px;
  box-shadow: inset 0px 0px 10px rgb(0, 0, 0);
}

.drum-pad:hover {
  color: white;
  background-color: rgb(104, 104, 104);
}

.controls {
  font-size: 25px;
  text-align: center;
  color: white;
}

#display {
  background-color: gold;
  color: rgb(56, 56, 56);
  width: 97%;
  margin-bottom: 2%;
  height: 80px;
  border-radius: 4px;
  font-size: 25px;
  text-align: center;
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, .7);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, .4);
}

section.volume > p {
  margin-bottom: 5px;
}

#volSlider {
  -webkit-appearance: none;
  width: 80%;
  height: 15px;
  background: white;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
  margin-bottom: 10px;
}

#volSlider:hover {
  opacity: 1;
}

#volSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  width: 25px;
  height: 25px;
  background: rgb(61, 61, 61);
  cursor: pointer;
}

#volSlider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: rgb(61, 61, 61);
  cursor: pointer;
  outline: none;
}
